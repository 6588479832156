import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  border: 1px solid white;
  color: white;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent white transparent transparent;
  }

  &:hover {
    text-decoration: inherit;
    background-color: white;
    color: ${props => props.theme.textColor};
  }
`;

const Download = props => {
  return (
    <Link href={props.url} target="_blank" rel="noopener noreferrer" {...props}>
      {props.children}
    </Link>
  );
}

export default Download;
import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Layout from './components/Layout';
import Header from './components/Header';
import styled from 'styled-components';

import cockpit from './utils/cockpit';
import Main from './pages/Main';
import Default from './pages/Default';
import Notare from './pages/Notare';
import Downloads from './pages/Downloads';

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class App extends React.Component {

  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    cockpit.getSingleton('imprint').then( res => {
      this.setState({
        impressum: res.data
      })
    });

    cockpit.getSingleton('main').then( res => {
      this.setState({
        main: res.data
      })
    });

    cockpit.getSingleton('profil').then( res => {
      this.setState({
        profil: res.data
      })
    });

    cockpit.getSingleton('notare').then( res => {
      this.setState({
        notare: res.data
      })
    });

    cockpit.getSingleton('kompetenzen').then( res => {
      this.setState({
        kompetenzen: res.data
      })
    });

    cockpit.getSingleton('kontakt').then( res => {
      this.setState({
        kontakt: res.data
      })
    });

    // cockpit.getSingleton('historie').then( res => {
    //   this.setState({
    //     historie: res.data || null
    //   })
    // });

    cockpit.getSingleton('team').then( res => {
      this.setState({
        team: res.data
      })
    });

    cockpit.getSingleton('downloads').then( res => {
      this.setState({
        downloads: res.data
      })
    });

    cockpit.getSingleton('Stellenanzeigen').then( res => {
      this.setState({
        stellenanzeigen: res.data
      })
    });
  }

  // componentDidUpdate(prevProps) {
    
  //   if (this.props.location !== prevProps.location) {
  //     this.onRouteChanged();
  //   }
  // }

  // onRouteChanged() {
  //   document.body.classList.remove('fixed');
  //   this.setState({
  //     showNav: false
  //   });
  // }

  render() {
    const { main, kompetenzen, kontakt, notare, profil, team, impressum, downloads, stellenanzeigen } = this.state;
    let result = null;
    result = <LoadingContainer>Seite wird geladen...</LoadingContainer>;

    if (main && kompetenzen && kontakt && notare && profil && team && impressum && downloads && stellenanzeigen) {
      result = (
        <React.Fragment>
          <Header />
          <main>
            <TransitionGroup>
              <CSSTransition 
                key={this.props.location.key} 
                classNames="fade" 
                timeout={{enter: 300, exit: 300}}
                mountOnEnter={true}
                unmountOnExit={true}>
                  
                <Switch>
                  <Route path="/" exact render={ props => <Main {...props} data={main} /> } />
                  <Route path="/profil" render={ props => <Default {...props} data={profil} title="Profil" /> } />
                  <Route path="/notare/:notar" render={ props => <Notare {...props} data={notare} title="Notare" /> } />
                  <Route path="/notare" render={ props => <Notare {...props} data={notare} title="Notare" /> } />
                  <Route path="/kompetenzen" render={ props => <Default {...props} data={kompetenzen} title="Kompetenzen" /> } />
                  {/* <Route path="/historie" render={ props => <Default {...props} data={historie} title="Historie" /> } /> */}
                  <Route path="/team" render={ props => <Default {...props} data={team} title="Team" /> } />
                  <Route path="/kontakt" render={ props => <Default {...props} data={kontakt} title="Kontakt" /> } />
                  <Route path="/downloads" render={ props => <Downloads {...props} data={downloads} title="Downloads" /> } />
                  <Route path="/stellenanzeigen" render={ props => <Default {...props} data={stellenanzeigen} title="Stellenanzeigen" /> } />
                  <Route path="/impressum" render={ props => <Default {...props} data={impressum} title="Impressum und Datenschutz" /> } />
                </Switch>

              </CSSTransition>
            </TransitionGroup>
          </main>
          
        </React.Fragment>
      );
    }

    return (
      <Layout>
        { result }
      </Layout>
    );
  }
}

export default withRouter(App);

import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;

  &:hover {
    & > div {
      display: flex;
      opacity: 1;
    }
  }

  img {
    width: 100%;
    vertical-align: top;
  }
`;

const Overlay = styled.div`
  padding: 30px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* display: none; */
    transition: opacity 0.2s ease-in-out;
  }

  * {
    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
      color: white !important;
    }
  }
`;

const OverlayImage = props => {
  return (
    <ImageContainer>
      <img src={props.image} alt="" />
      <Overlay>
        <div>
          {props.children}
        </div>
      </Overlay>
    </ImageContainer>
  );
}

export default OverlayImage;
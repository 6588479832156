import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import Navigation from '../components/Navigation';
import MobileToggle from '../components/MobileToggle';

const HeaderContainer = styled.div``;

const HeaderContainerFlex = styled.div`
  margin: 0 auto;
  height: 80px;
  max-width: ${props => props.theme.maxWidth};
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 168px;
    padding-left: ${props => props.theme.gapHeader};
    padding-right: ${props => props.theme.gapHeader};
  }
`;

const Logo = styled.div`
  padding: 30px 0;

  img {
    width: 80px;

    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
      width: 150px;
    }
  }
`;

const StyledNavigation = styled(Navigation)`
  position: absolute;
  // height: 300px;
  z-index: 10;
  width: 100%;
  height: 100vh;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    // height: 462px;
  }
`;

const Row = styled.div`
  // width: 50%;
  position: relative;
`;

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  position: relative;
  text-align: left;
`;

const Toggle = styled.div`
  cursor: pointer;
  width: 39px;

  svg {
    fill: ${props => props.theme.textColor};
    width: 100%;
  }
`;


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showNav: false
    };
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.showNav);
    if (this.state.showNav !== this.props.showNav) {
      this.setState({showNav: false});
    }
  }

  toggleHandler = () => {
    document.body.classList.toggle('fixed');
    this.setState(state => ({ showNav: !state.showNav }));
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.body.classList.remove('fixed');
    this.setState({
      showNav: false
    });
  }


  render() {
    return (
      <HeaderContainer>
        <HeaderContainerFlex>
          <Toggle onClick={this.toggleHandler}>
            <svg width="39px" height="21px" viewBox="0 0 39 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g id="320-CORE-/-HEADER" transform="translate(-141.000000, -19.000000)" stroke="#81725E" stroke-width="3">
                  <g id="Group-2" transform="translate(143.000000, 20.000000)">
                    <path d="M0.426829268,0.5 L34.8461914,0.5" id="Line-3"></path>
                    <path d="M0.426829268,9.5 L34.8461914,9.5" id="Line-3-Copy"></path>
                    <path d="M0.426829268,18.5 L34.8461914,18.5" id="Line-3-Copy-2"></path>
                  </g>
                </g>
              </g>
            </svg>
          </Toggle>
          <Logo>
            <Link to="/"><img src={logo} alt="" /></Link>
          </Logo>
        </HeaderContainerFlex>
        {this.state.showNav ? (
        
            <StyledNavigation />
        
        ) : null}
        
      </HeaderContainer>
    );
  }
}

export default withRouter(Header);



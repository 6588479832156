import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const PageContainer = styled.div`
  max-width: ${props => props.theme.pageMaxWidth};
  padding: 0 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    padding: 0;
    margin-left: ${props => props.theme.gapHeader};
  }
`;

const Container = styled.div`
  padding: 0 10px;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const HeaderImage = styled.div`
  height: 300px;
  height: 150px;
  background: url('${props => props.url}') center center no-repeat;
  margin-bottom: 60px;
  background-size: cover;

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 462px;
  } */

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 300px;
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    height: 400px;
  }
`;


const Headline = styled.h2`
  flex: 1;
`;

const StyledLink = styled(Link)`
  float: right;
  vertical-align: bottom;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }
`;

const Header = styled.header`
  display: flex;
  /* &::before,
  &::after {
    content: " ";
    display: table;
  } 

  &::after {
    clear: both;
  } */
`;

const Default = props => {
  return (
    <Page title={props.title}>
      {props.data.image ? (
        <HeaderImage url={`${baseUrl}/${props.data.image.path}`} alt="" />
      ) : null}
      
      <Container>
        <PageContainer>  
          <Header>
            <Headline>{props.data.headline}</Headline>
            <StyledLink to="/">x close</StyledLink>
          </Header>
          <div dangerouslySetInnerHTML={{ __html: props.data.text }}></div>
        </PageContainer>
      </Container>
    </Page>
  );
}

export default Default;
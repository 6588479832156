import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
`;

const Content = styled.div`
  /* padding: 0 10px;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto; */

  img {
    width: 100%;
  }
`;

const StyledFooter = styled(Footer)`
  margin-top: 100px;
`;


const Page = props => {
  return (
    <PageContainer {...props}>
      <Helmet><title>Regler Sikora Notare - {props.title}</title></Helmet>
      <Content>{props.children}</Content>
      <StyledFooter />
    </PageContainer>
  );
}

export default Page;
import React from 'react';
import styled from 'styled-components';
import Navigation from '../components/Navigation';

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: block;
  margin: 0 auto;

  &:focus {
    outline: none;
  }
`;

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
  overflow: scroll;
  color: white;
  padding: 50px;
  font-style: italic;

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    display: none;
  }
`;

class MobileToggle extends React.Component {
  state = { showModal: false }

  toggleModal = () => {
    this.setState(oldState => ({
      showModal: !oldState.showModal
    }))
  }

  render() {
  
    return (
      <div>
        <Button onClick={this.toggleModal} id="toggle" {...this.props}>
          <svg width="39px" height="21px" viewBox="0 0 39 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
              <g id="320-CORE-/-HEADER" transform="translate(-141.000000, -19.000000)" stroke="#333333" stroke-width="3">
                <g id="Group-2" transform="translate(143.000000, 20.000000)">
                  <path d="M0.426829268,0.5 L34.8461914,0.5" id="Line-3"></path>
                  <path d="M0.426829268,9.5 L34.8461914,9.5" id="Line-3-Copy"></path>
                  <path d="M0.426829268,18.5 L34.8461914,18.5" id="Line-3-Copy-2"></path>
                </g>
              </g>
            </g>
          </svg>
        </Button>

        {this.state.showModal ? (
          <Modal>
            <Button onClick={this.toggleModal} id="toggle" {...this.props}>
              <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                  <g id="320-CORE-/-HEADER-Copy" transform="translate(-148.000000, -18.000000)" stroke="#FFFFFF" stroke-width="3">
                    <g id="Group" transform="translate(149.000000, 19.000000)">
                      <path d="M0.5,1 L22,22.5" id="Line-2"></path>
                      <path d="M1,1 L22.5,22.5" id="Line-2-Copy" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "></path>
                    </g>
                  </g>
                </g>
              </svg>
            </Button>
            <Navigation clickHandler={this.toggleModal} />
          </Modal>
        ) : null}
        
      </div>
    );
  }
}
  
export default MobileToggle;
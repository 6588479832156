import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  background-color: rgba(129, 114, 94, 0.6);

  @media only screen and (min-width: ${props => props.theme.bp.large}) {
    margin-top: 0;
  }
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    margin: 30px 0 0 100px;
  }
`;

const NavItem = styled.li`
  margin-bottom: 5px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    margin-bottom: 12px;
  }

  #notareNav {
    display: none;

    a {
      color: white;
      text-decoration: none;
      font-size: 1.8rem;

      @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
        font-size: 2rem;
      }
    }
    
    &.visible {
      display: block;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  line-height: 1;
  font-weight: normal;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    font-size: 30px;
  }

  &:hover {
    text-decoration: inherit;
    color: white;
  }
`;

class Navigation extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.getElementById('notareToggle').addEventListener('click', function(e) {
      e.preventDefault();
      document.getElementById('notareNav').classList.toggle('visible');
    });
  }
  
  render() {
    return (
      <Nav {...this.props}>
        <NavList>
          <NavItem><StyledNavLink nested to="/profil">Profil</StyledNavLink></NavItem>
          <NavItem>
            <StyledNavLink nested to="/notare/sikora" id="notareToggle">Notare</StyledNavLink>
            <ul id="notareNav">
              <li><NavLink to="/notare/regler">Dr. Rainer Regler</NavLink></li>
              <li><NavLink to="/notare/sikora">Dr. Markus Sikora</NavLink></li>
            </ul>
          </NavItem>
          <NavItem><StyledNavLink nested to="/kompetenzen">Kompetenzen</StyledNavLink></NavItem>
          {/* <NavItem><StyledNavLink nested to="/historie">Historie</StyledNavLink></NavItem> */}
          <NavItem><StyledNavLink nested to="/team">Team</StyledNavLink></NavItem>
          <NavItem><StyledNavLink nested to="/kontakt">Kontakt</StyledNavLink></NavItem>
          <NavItem><StyledNavLink nested to="/downloads">Downloads</StyledNavLink></NavItem>
          <NavItem><StyledNavLink nested to="/stellenanzeigen">Stellenanzeigen</StyledNavLink></NavItem>
        </NavList>
      </Nav>
    );
  }
}

export default Navigation;
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import OverlayImage from '../components/OverlayImage';
import Download from '../components/Download';
import Footer from '../components/Footer';
import baseUrl from '../utils/baseUrl';

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const TextContainerProfil = styled.div`
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto;
`;

const ProfilContainer = styled.div`
  padding: 60px 0 20px 0;
  height: 100%;

  ${props => props.border && css`
    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
      border-right: 1px solid ${props => props.theme.textColor};
    }
  `}
`;

const SectionContainer = styled.div`
  padding: 30px 0;
`;

const TextContainerHistorie = styled.div`
  max-width: 800px;
  padding: 60px 10px;
  margin: 0 auto;
`;

const TextContainerKontakt = styled.div`
  max-width: 800px;
  padding: 60px 10px;
  margin: 0 auto;
`;

const ReglerContainer = styled.div`
  margin-bottom: 30px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    margin-bottom: 0;
  }
`;

const BackgroundImage = styled.div`
  height: 150px;
  background: url('${props => props.url}') center center no-repeat;
  background-size: cover;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 300px;
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    height: 400px;
  }
`;

const BackgroundImage2 = styled.div`
  height: 460px;
  background: url('${props => props.url}') center center no-repeat;
  background-size: cover;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 460px;
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TeamContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: white;
`;

const DownloadContainer = styled.div`
  color: white;
  background-color: ${props => props.theme.textColor};
  padding: 60px 10px;
`;

const TextContainerDownloads = styled.div`
  max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 30px;
`;

const DownloadFlexContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const StyledDownload = styled(Download)`
  flex: 1;
  height: 100%;
  margin: 0 10px;
`;

const StyledDownloadCol = styled(Col)`
  margin-bottom: 10px;
`;

const MoreLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;

  &:hover {
    text-decoration: inherit;
  }
`;

const Corona = styled.div`
  background-color: ${props => props.theme.textColor};
  color: white; 
  padding: 30px;
  font-size: 22px;
`;

const Main = props => {
  return (
    <div>
      <BackgroundImage url={`${baseUrl}/${props.data.mood.path}`} />
      <Container>

        { props.data.coronahinweis_headline ? <Corona><div dangerouslySetInnerHTML={{ __html: props.data.coronahinweis_headline }}></div></Corona> : null }
      
        {/* Profil & Kompetenzen */}
        <SectionContainer>
          <Row>
            <Col xs={12} sm={6}>
              <ProfilContainer border>
                <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                  <TextContainerProfil>
                    <h2>{props.data.profil_headline}</h2>
                    <div dangerouslySetInnerHTML={{ __html: props.data.profil_text }}></div>
                    <MoreLink to="/profil">> mehr</MoreLink>
                  </TextContainerProfil>
                </ScrollAnimation>
              </ProfilContainer>
            </Col>
            <Col xs={12} sm={6}>
              <ProfilContainer>
                <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                  <TextContainerProfil>
                    <h2>{props.data.kompetenzen_headline}</h2>
                    <div dangerouslySetInnerHTML={{ __html: props.data.kompetenzen_text }}></div>
                    <MoreLink to="/kompetenzen">> mehr</MoreLink>
                  </TextContainerProfil>
                </ScrollAnimation>
              </ProfilContainer>
            </Col>
          </Row>
        </SectionContainer>

        {/* Regler & Sikora */}
        <Row>
          <Col xs={12} sm={6}>
            <ReglerContainer>
              <OverlayImage image={`${baseUrl}/${props.data.regler_image.path}`}>
                <h2>{props.data.regler_headline}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.data.regler_text }}></div>
                <MoreLink to="/notare/regler">> mehr</MoreLink>
              </OverlayImage>
            </ReglerContainer>
          </Col>
          <Col xs={12} sm={6}>
            <OverlayImage image={`${baseUrl}/${props.data.sikora_image.path}`}>
              <h2>{props.data.sikora_headline}</h2>
              <div dangerouslySetInnerHTML={{ __html: props.data.sikora_text }}></div>
              <MoreLink to="/notare/sikora">> mehr</MoreLink>
            </OverlayImage>
          </Col>
        </Row>

        {/* Historie */}
        <SectionContainer>
          <Row>
            <Col xs={12} sm={12}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={false}>
                <TextContainerHistorie>
                  <h2>{props.data.historie_headline}</h2>
                  <div dangerouslySetInnerHTML={{ __html: props.data.historie_text }}></div>
                  {/* <MoreLink to="/historie">> mehr</MoreLink> */}
                </TextContainerHistorie>
              </ScrollAnimation>
            </Col>
          </Row>
        </SectionContainer>

        {/* Team */}
        <Row>
          <Col xs={12} sm={12}>
            <BackgroundImage2 url={`${baseUrl}/${props.data.team_image.path}`}>
              <ContainerFlex>
                <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                  <TeamContainer>
                    <h2>{props.data.team_headline}</h2>
                    <div dangerouslySetInnerHTML={{ __html: props.data.team_text }}></div>
                    <MoreLink to="/team">> mehr</MoreLink>
                  </TeamContainer>
                </ScrollAnimation>
              </ContainerFlex>
            </BackgroundImage2>
          </Col>
        </Row>

        {/* Kontakt */}
        <SectionContainer>
          <Row>
            <Col xs={12} sm={12}>
              <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                <TextContainerKontakt>
                  <h2>{props.data.kontakt_headline}</h2>
                  <div dangerouslySetInnerHTML={{ __html: props.data.kontakt_text }}></div>
                </TextContainerKontakt>
              </ScrollAnimation>
            </Col>
          </Row>
        </SectionContainer>

        {/* Downloads */}
        <Row>
          <Col xs={12} sm={12}>
            <DownloadContainer>
              <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                <TextContainerDownloads>
                  <h2>{props.data.downloads_headline}</h2>
                  <div dangerouslySetInnerHTML={{ __html: props.data.downloads_text }}></div>
                  <MoreLink to="/downloads">> mehr</MoreLink>
                </TextContainerDownloads>
                <DownloadFlexContainer>
                  <Row>
                    {props.data.downloads_items.map(dl => (
                      <StyledDownloadCol xs={6} sm={3}>
                        <StyledDownload url={`${baseUrl}/${dl.value.file}`}>{dl.value.title}</StyledDownload>
                      </StyledDownloadCol>
                    ))}
                  </Row>
                </DownloadFlexContainer>
              </ScrollAnimation>
            </DownloadContainer>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Main;
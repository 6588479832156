import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';
import Download from '../components/Download';

const PageContainer = styled.div`
  max-width: ${props => props.theme.pageMaxWidth};
  padding: 0 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    padding: 0;
    margin-left: ${props => props.theme.gapHeader};
  }
`;

const Container = styled.div`
  padding: 0 10px;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const StyledDownloadCol = styled(Col)`
  margin-bottom: 10px;
`;

const Headline = styled.h2`
  flex: 1;
`;

const HeaderImage = styled.div`
  height: 300px;
  background: url('${props => props.url}') center center no-repeat;
  margin-bottom: 60px;
  background-size: cover;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 462px;
  }
`;

const StyledLink = styled(Link)`
  float: right;
  vertical-align: bottom;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }
`;

const Header = styled.header`
  display: flex;
  /* &::before,
  &::after {
    content: " ";
    display: table;
  } 

  &::after {
    clear: both;
  } */
`;

const DownloadList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  
`;

const DownloadItem = styled.li`
  height: 100%;

  a {
    border: 1px solid #817260;
    text-decoration: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    height: 100%;
    margin: 0px 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: white;
      background-color: #817260;
    }
  }
`;



const Downloads = props => {
  return (
    <Page title="Downloads">
      {props.data.image ? (
        <HeaderImage url={`${baseUrl}/${props.data.image.path}`} alt="" />
      ) : null}
      
      <Container>
        <PageContainer>  
          <Header>
            <Headline>{props.data.headline}</Headline>
            <StyledLink to="/">x close</StyledLink>
          </Header>
          <div dangerouslySetInnerHTML={{ __html: props.data.text }}></div>

          <DownloadList>
            <Row>
              {props.data.downloads.map(item => (
                <StyledDownloadCol xs={6} sm={3}>
                  <DownloadItem>
                    <a href={`${baseUrl}/${item.value.file}`} target="_blank">{item.value.label}</a>
                  </DownloadItem>
                </StyledDownloadCol>
              ))}
            </Row>
          </DownloadList>
        </PageContainer>
      </Container>
    </Page>
  );
}

export default Downloads;
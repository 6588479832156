import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.div`
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }
`;

const Footer = props => {
  return (
    <FooterContainer {...props}>
      <StyledLink to="/impressum">Impressum | Datenschutz</StyledLink>
    </FooterContainer>
  );
}

export default Footer;
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Page from '../components/Page';
import baseUrl from '../utils/baseUrl';

const PageContainer = styled.div`
  max-width: ${props => props.theme.pageMaxWidth};
  padding: 0 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    padding: 0;
    margin-left: ${props => props.theme.gapHeader};
  }
`;

const Container = styled.div`
  padding: 0 10px;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const HeaderImage = styled.div`
  height: 300px;
  background: url('${props => props.url}') center center no-repeat;
  margin-bottom: 60px;
  background-size: cover;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    height: 462px;
  }
`;

const Headline = styled.h2`
  /* flex: 1; */
`;

const Nav = styled.div`
  flex: 1;
`;

const StyledLink = styled(Link)`
  /* float: right; */
  /* flex: 1; */
  vertical-align: bottom;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: 2.2rem;

  &:hover {
    text-decoration: inherit;
  }
`;

const NavSeperator = styled.span`
  display: inline-block;
  margin: 0 10px;
`;

const Notare = props => {
  console.log(props.data);

  const notar = props.match.params.notar;
  const image = props.data[`image_${notar}`].path || null;
  const headline = props.data[`headline_${notar}`] || null;
  const text = props.data[`text_${notar}`] || null;

  return (
    <Page title={props.title}>
      <HeaderImage url={`${baseUrl}/${image}`} alt="" />

      <Container>
        <PageContainer>  
          <Header>
            <Nav><NavLink to="/notare/regler">Dr. Rainer Regler</NavLink> <NavSeperator>|</NavSeperator> <NavLink to="/notare/sikora">Dr. Markus Sikora</NavLink></Nav>
            <StyledLink to="/">x close</StyledLink>
          </Header>
          <Headline>
            {headline}
          </Headline>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </PageContainer>
      </Container> 
    </Page>
  );
}

export default Notare;